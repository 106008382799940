@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

html, body {
  height: 100%;
  width: 100%;
}

#root {
  width: 100%;
  display: flex;
}

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
